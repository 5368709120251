import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import logo from './logo.png';
import Card from './components/Card/Card';
import Modal from './components/Modal/Modal'; // Adjust the path as necessary



import ninja from './ninja.png';
import yuki from './yuki.jpeg';
import kira from './kira.jpeg';
import ginger from './ginger.png';
import injustice from './injustice.jpeg';
import injectiveinu from './injectiveinu.jpeg';
import samurainj from './samurainj.jpeg';
import katana from './katana.jpeg';
import bonk from './bonk.jpg';
import shinobi from './shinobi.jpeg';


import ninjaDump from './ninja_dump.png';
import yukiDump from './yuki_dump.png';
import kiraDump from './kira_dump.png';
import gingerDump from './ginger_dump.png';
import injusticeDump from './injustice_dump.png';
import injectiveinuDump from './injectiveinu_dump.png';
import samurainjDump from './samurainj_dump.png';
import bonkDump from './bonk_dump.png';
import katanaDump from './katana_dump.png';
import shinobiDump from './shinobi_dump.png';


import pumpImage from './wojakgreen.jpeg'; // Correct image path
import dumpImage from './redcryingwojak.png'; // Correct image path

import soundfile from './sound.mp3';
import soundfile1 from './shotgun-sound-effect.mp3';
import prisonsound from './prison.mp3';

import runningDogGif from './runningdog.gif';

import doj from './doj.png';

import prison from './prison.gif';

import CustomCursor from './components/Cursor/Cursor'; // Adjust the path as necessary
import cursorImage from './cursor.png'; // Adjust the path as necessary





function App() {



  const ribbonRef = useRef();
  const rug = new Audio(soundfile1);
  const pump = new Audio(soundfile);
  const [showPumpImage, setShowPumpImage] = useState(false);
  const [showDumpImage, setShowDumpImage] = useState(false);
  const [imageStyle, setImageStyle] = useState({});


  const [ninjaImage, setNinjaImage] = useState(ninja);
  const [yukiImage, setYukiImage] = useState(yuki);
  const [kiraImage, setKiraImage] = useState(kira);
  const [gingerImage, setGingerImage] = useState(ginger);
  const [injusticeImage, setInjusticeImage] = useState(injustice);
  const [injectiveinuImage, setInjectiveinuImage] = useState(injectiveinu);
  const [samurainjImage, setSamurainjImage] = useState(samurainj);
  const [bonkImage, setBonkImage] = useState(bonk);
  const [shinobiImage, setShinobiImage] = useState(shinobi);
  const [katanaImage, setKatanaImage] = useState(katana);



  const [showPrisonGif, setShowPrisonGif] = useState(false);
  const prisonSound = new Audio(prisonsound);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(process.env.PUBLIC_URL + '/sound.mp3'));

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    // Force restart the animation
    const ribbon = ribbonRef.current;
    ribbon.style.animation = 'none';
    setTimeout(() => {
      ribbon.style.animation = '';
    }, 10);
  }, []);

  const pumpAnimation = () => {
  const randomX = Math.random() * window.innerWidth;
  const randomY = Math.random() * window.innerHeight;
  setImageStyle({ top: `${randomY}px`, left: `${randomX}px`, position: 'absolute', opacity: 1, zIndex: 1000 });

  pump.play();
  setShowPumpImage(true); // Use setShowPumpImage here

  setTimeout(() => {
    setImageStyle((prevStyle) => ({ ...prevStyle, opacity: 0 }));
    setTimeout(() => setShowPumpImage(false), 2000); // Use setShowPumpImage here for fade-out effect
  }, 3000);
};

  const handleNinjaRugClick = () => {
    setNinjaImage(ninjaDump);
    rug.play();
    setTimeout(() => setNinjaImage(ninja), 5000);
  };

  const handleYukiRugClick = () => {
    setYukiImage(yukiDump);
    rug.play();
    setTimeout(() => setYukiImage(yuki), 5000);
  };

  const handleKiraRugClick = () => {
    setKiraImage(kiraDump);
    rug.play();
    setTimeout(() => setKiraImage(kira), 5000);
  };
  const handleGingerRugClick = () => {
    setGingerImage(gingerDump);
    rug.play();
    setTimeout(() => setGingerImage(ginger), 5000);
  };

  const handleInjusticeRugClick = () => {
    setInjusticeImage(injusticeDump);
    rug.play();
    setTimeout(() => setInjusticeImage(injustice), 5000);
  };

  const handleInjectiveinuRugClick = () => {
    setInjectiveinuImage(injectiveinuDump);
    rug.play();
    setTimeout(() => setInjectiveinuImage(injectiveinu), 5000);
  };

  const handleSamurainjRugClick = () => {
    setSamurainjImage(samurainjDump);
    rug.play();
    setTimeout(() => setSamurainjImage(samurainj), 5000);
  };

  const handleBonkRugClick = () => {
    setBonkImage(bonkDump);
    rug.play();
    setTimeout(() => setBonkImage(bonk), 5000);
  };

  const handleShinobiRugClick = () => {
    setShinobiImage(shinobiDump);
    rug.play();
    setTimeout(() => setShinobiImage(shinobi), 5000);
  };
  const handleKatanaRugClick = () => {
    setKatanaImage(katanaDump);
    rug.play();
    setTimeout(() => setKatanaImage(katana), 5000);
  };
  const handleNoBribeClick = () => {
    setShowPrisonGif(true); // Show the GIF
    prisonSound.play();

    setTimeout(() => {
      setShowPrisonGif(false); // Hide the GIF after some seconds
    }, 3000);
  };

    const handleBribeClick = () => {
    pump.play();
    window.open('https://coinhall.org/injective/inj1g8ftaglcmyqsang8gwgl7tg2n222x09yf42cm7', '_blank');
  };





  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  return (

    <div className="App" >

    <CustomCursor imgSrc={cursorImage} />


    {showPumpImage && <img src={pumpImage} alt="Animated Wojak" style={imageStyle} />}
    {showDumpImage && <img src={dumpImage} alt="Animated Dumpjak" style={imageStyle} />}
      {showPrisonGif && (
        <div className="fullscreen-gif" onClick={() => setShowPrisonGif(false)}>
          <img src={prison} alt="Prison Gif" />
        </div>
      )}

           <div className="ribbon" ref={ribbonRef}>
        <span> In case of an investigation by any federal entity or similar, I do not have any involvement with this group or with the people in it, I do not know how I got here, probably added by a third party, I do not support any actions by members of this group.
          En caso de una investigación por parte de alguna entidad federal o similar, no tengo ninguna participación con este grupo ni con las personas en él, no sé cómo llegué aquí, probablemente fui añadido por una tercera parte, no apoyo ninguna acción de los miembros de este grupo.
          En cas d'enquête par une entité fédérale ou similaire, je n'ai aucune implication avec ce groupe ou avec les personnes qui le composent, je ne sais pas comment j'ai atterri ici, probablement ajouté par un tiers, je ne soutiens aucune action des membres de ce groupe.
          Im Falle einer Untersuchung durch eine Bundesbehörde oder ähnliches, habe ich keine Verbindung zu dieser Gruppe oder den darin befindlichen Personen. Ich weiß nicht, wie ich hier gelandet bin, wahrscheinlich wurde ich von einer Drittpartei hinzugefügt. Ich unterstütze keine Handlungen der Mitglieder dieser Gruppe.
          如果有任何联邦实体或类似机构的调查，我与这个团体或其中的人员没有任何关联，我不知道我是如何到这里的，可能是由第三方添加的，我不支持这个团体成员的任何行动。
          連邦機関または類似の機関による調査の場合、私はこのグループやそのメンバーとは一切関与していません。ここにどのようにして来たのか、おそらく第三者によって追加されたものと考えられます。このグループのメンバーの行動を支持していません。
          В случае расследования федеральным органом или аналогичной организацией, у меня нет никакого отношения к этой группе или к людям в ней. Я не знаю, как я сюда попал, возможно, меня добавили третьи лица. Я не поддерживаю никаких действий членов этой группы.
          في حالة التحقيق من قبل أي جهة فدرالية أو مماثلة، فأنا لا أملك أي تورط مع هذه المجموعة أو مع الأشخاص فيها، ولا أعلم كيف وصلت إلى هنا، ربما تمت إضافتي من قبل طرف ثالث، وأنا لا أدعم أي أفعال من قبل أعضاء هذه المجموعة.
          Em caso de investigação por parte de alguma entidade federal ou similar, eu não tenho qualquer envolvimento com este grupo ou com as pessoas nele, não sei como cheguei aqui, provavelmente fui adicionado por uma terceira parte, não apoio quaisquer ações dos membros deste grupo.
          In caso di un'indagine da parte di un ente federale o simile, non ho alcuna relazione con questo gruppo o con le persone al suo interno, non so come sono arrivato qui, probabilmente aggiunto da terze parti, non sostengo alcuna azione dei membri di questo gruppo.
          연방 기관이나 유사한 조사의 경우, 나는 이 그룹이나 이 그룹의 사람들과 어떠한 관련도 없습니다. 여기에 어떻게 왔는지 모르겠으며, 아마도 제3자가 추가한 것일 것입니다. 이 그룹의 구성원들의 어떠한 행동도 지지하지 않습니다.
          In geval van onderzoek door een federale entiteit of iets dergelijks, heb ik geen enkele betrokkenheid bij deze groep of de mensen erin, ik weet niet hoe ik hier ben beland, waarschijnlijk toegevoegd door een derde partij, ik ondersteun geen enkele actie van de leden van deze groep.
          Herhangi bir federal kurum veya benzeri bir inceleme durumunda, bu grupta veya içindeki insanlarla hiçbir ilişkim yok, nasıl buraya geldiğimi bilmiyorum, muhtemelen üçüncü bir taraf tarafından eklenmişimdir, bu grubun üyelerinin herhangi bir eylemini desteklemiyorum.
          I händelse av en undersökning av någon federal myndighet eller liknande, har jag ingen koppling till denna grupp eller de personer som ingår i den. Jag vet inte hur jag hamnade här, förmodligen har jag lagts till av en tredje part. Jag stöder inte några åtgärder som tas av medlemmar i denna grupp.
          Σε περίπτωση έρευνας από οποιοδήποτε ομοσπονδιακό ή παρόμοιο φορέα, δεν έχω καμία σχέση με αυτήν την ομάδα ή με τα άτομα που την απαρτίζουν, δεν ξέρω πώς βρέθηκα εδώ, πιθανόν προστέθηκα από τρίτο μέρος, δεν υποστηρίζω καμία δράση από τα μέλη αυτής της ομάδας.
          किसी संघटन के द्वारा या उसके समकक्ष किसी जांच के मामले में, मेरे पास इस समूह या इसमें शामिल लोगों के साथ कोई संलग्नता नहीं है, मुझे यह नहीं पता कि मैं यहां कैसे पहुंचा, शायद तीसरी पक्ष द्वारा जोड़ दिया गया हो, मैं इस समूह के सदस्यों के किसी भी क्रिया का समर्थन नहीं करता।</span>
      </div>


      <header className="header">
            <img src={doj}
        alt="Play/Pause"
        onClick={togglePlayPause}
        className="header-image"
      />
        <h1>  ← 🎵 Top Ten Most Wanted  🎵 → </h1>

                    <img src={doj}
        alt="Play/Pause"
        onClick={togglePlayPause}
        className="header-image"
      />
      </header>


      <div className="main-content">

        <audio autoPlay loop>
          <source src={process.env.PUBLIC_URL + '/sound.mp3'} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        <section className="content">
          {/* Main content goes here */}
          <Card
            name="NINJA"
            image={ninjaImage}
            onPumpClick={pumpAnimation}
            onDumpClick={handleNinjaRugClick}
          />

        <Card
          name="BRIBE THE DOJ"
          image={doj}
          handleBribeClick={handleBribeClick}
          handleNoBribeClick={handleNoBribeClick}
        />

          <Card
            name="YUKI"
            image={yukiImage}
            
            onPumpClick={pumpAnimation}
            onDumpClick={handleYukiRugClick}
          />
          <Card
            name="GINGER"
            image={gingerImage}
             
            onPumpClick={pumpAnimation} 
            onDumpClick={handleGingerRugClick} // No need to use img tag here, just pass the imported image
          />
          <Card name="INJUST" image={injusticeImage}  onPumpClick={pumpAnimation} onDumpClick={handleInjusticeRugClick} /> 


          <Card name="INJINU" image={injectiveinuImage}  onPumpClick={pumpAnimation} onDumpClick={handleInjectiveinuRugClick} />


          <Card name="SAM" image={samurainjImage}  onPumpClick={pumpAnimation} onDumpClick={handleSamurainjRugClick} />


          <Card name="BONK" image={bonkImage}  onPumpClick={pumpAnimation} onDumpClick={handleBonkRugClick} />


          <Card name="NOBI" image={shinobiImage}  onPumpClick={pumpAnimation} onDumpClick={handleShinobiRugClick} />

          <Card
            name="KATANA"
            image={katanaImage}
             
            onPumpClick={pumpAnimation} 
            onDumpClick={handleKatanaRugClick} // No need to use img tag here, just pass the imported image
          />
          <Card
            name="KIRA"
            image={kiraImage}
            
            onPumpClick={pumpAnimation}
            onDumpClick={handleKiraRugClick}
          />
          {/* Add additional Card components as needed */}
        </section>
      </div>


      <footer className="footer">
        <img src={process.env.PUBLIC_URL + '/footer-logo.png'} alt="Footer Logo" className="Footer-logo" />
        {'Woofderal Bureau of Woofstigation'}

              <button className="disclaimer-btn" onClick={handleOpenModal}>Disclaimer</button>
      
      <Modal show={showModal} onClose={handleCloseModal}>
        <h2>Disclaimer</h2>
<p style={{ color: 'black' }}>
  <strong>Disclaimer</strong><br /><br />

  <strong>General Notice:</strong> The DOJ is a digital meme token. It is important for potential holders to understand that DOJ is purely for entertainment and symbolic purposes and should not be considered as an investment or a financial instrument.<br /><br />

  <strong>No Financial Advice:</strong> The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice, and you should not treat any of the website's content as such. The DOJ team does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.<br /><br />

  <strong>No Guarantee of Value:</strong> DOJ has no intrinsic value, is not backed by any physical or digital assets, and has no guarantee of value preservation. The price and value of DOJ are extremely volatile and can significantly decrease. There is a risk of loss when purchasing, selling, or holding this token.<br /><br />

  <strong>Compliance with Laws:</strong> It is your responsibility to ensure that your interaction with DOJ, including holding, trading, or utilizing the token for any purpose, complies with local laws and regulations in your jurisdiction.<br /><br />

  <strong>Acknowledgment:</strong> By using this website and engaging with DOJ, you acknowledge and agree that you have understood these risks and that you are solely responsible for your actions. DOJ and its team shall not be held liable for any loss or damage arising from your use of the token or reliance on the information provided on this website.<br /><br />

  <strong>Changes to the Disclaimer:</strong> The DOJ team reserves the right to change or update this disclaimer at any time, and any such changes will be posted on our website.
</p>


      </Modal>


      </footer>
    </div>
  );
}

export default App;
