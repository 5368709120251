import React, { useState } from 'react';
import './Card.css';

function Card({ name, image, info, onPumpClick, onDumpClick, handleBribeClick, handleNoBribeClick }) {
  const [showInfoBox, setShowInfoBox] = useState(false);

  const toggleInfoBox = () => {
    setShowInfoBox(!showInfoBox);
  };

  const isSpecialCard = handleBribeClick && handleNoBribeClick;

  return (
    <div className="card">
      <img src={image} alt={name} className="card-image" onClick={toggleInfoBox} />

      {showInfoBox && (
        <div className="info-box">
          <p>{info}</p>
          {isSpecialCard ? (
            <>
              <button id="bribeButton" onClick={handleBribeClick}>Bribe</button>
              <button id="nobribeButton" onClick={handleNoBribeClick}>No Bribe</button>
            </>
          ) : (
            <>
              <button id="pumpButton" onClick={onPumpClick}>PUMP</button>
              <button id="dumpButton" onClick={onDumpClick}>RUG</button>
            </>
          )}
        </div>
      )}

      <div className="info-bubble">{info}</div>

      <div className="card-info">
        <h5 className="card-name">{name}</h5>
        {/* Add other details here */}
      </div>
    </div>
  );
}

export default Card;
